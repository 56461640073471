import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import admindash from "../../Assets/Projects/admindash.jpg";
import VSC from "../../Assets/Projects/VSC.jpg";
import portfoli from "../../Assets/Projects/portfoli.png";
import BookAtlas from "../../Assets/Projects/Book-Atlas.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={VSC}
              isBlog={false}
              title="V-S-C (Workshop Management System)"
              description="This side project, called V-S-C, is designed to manage all aspects of running a workshop. The system includes features for inventory management, invoicing, employee administration, customer and vehicle tracking, and maintenance scheduling. The goal of the project is to create an all-in-one solution to streamline workshop operations efficiently."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={admindash}
              isBlog={false}
              title="Admin Dashboard (Side Project)"
              description="This Admin Dashboard is a side project developed to streamline user and role management within a system. It includes features such as adding and removing users, assigning roles (Admin, Depot Manager, Driver, Mechanic, Traffic Manager), updating passwords, and reviewing error logs. Additionally, a user list search function allows for efficient user management. This project was created as a functional tool to simplify administrative tasks and improve operational workflows."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={portfoli}
              isBlog={false}
              title="My Old Portfolio"
              description="Personal portfolio website that I created just three months after starting my education in frontend development. The site showcases my skills and projects, featuring sections like 'About Me,' 'Education,' 'Portfolio,' and 'Contact.' It includes examples of my work, such as a Hangman game, a Beer API, and a Time Alarms app, highlighting my ability to build functional and interactive web applications.

The website served as an early milestone in my learning journey but has not been updated for over 10 months, reflecting my progress since its creation."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={BookAtlas}
              isBlog={false}
              title="Book Atlas"
              description="Book Atlas is a React-based application that allows users to search for books, view book details, mark books as read, and manage their favorite books. The app fetches data from the Open Library API and provides a user-friendly interface for book lovers."
              link="https://github.com/Lucifer87878/Book-Atlas"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
